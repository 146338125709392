import { CSSProperties } from "react"

const staticBackgroundStyles: CSSProperties = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
}

export default staticBackgroundStyles
