import React, { FC } from "react"
import styled from "styled-components"
import { grey } from "../../../../../constants/colors"
import { caflischFont } from "../../../../../constants/fonts"
import { desktop, smallMobile, tablet } from "../../../../../constants/screenSizes"
import IllustrationRippleBlue from "../../../../../../svg/illustration-ripple-blue.svg"
import ColorWrapper from "../../../ColorWrapper"
import Block from "../../../Block"
import Part from "../../../Part"
import Title from "../../../Title"
import LargeTitle from "../../../Title"
import Description from "../../../Description"

const OurselvesBlock = styled.div`
  max-width: 1200px;
  padding-top: 50px;
  display: flex;

  @media (max-width: ${desktop}px) {
    flex-wrap: wrap;
  }

  @media (max-width: ${tablet}px) {
    flex-wrap: wrap;
  }
`

const OurselvesItem = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  &:nth-child(3) {
    padding-right: 0;
  }

  @media (max-width: ${desktop}px) {
    max-width: 320px;

    &:nth-child(2) {
      padding-top: 170px;
      margin-right: 0;
    }
    &:nth-child(3) {
      padding-right: 0;
    }
  }

  @media (max-width: ${tablet}px) {
    max-width: 250px;
  }

  @media (max-width: 565px) {
    max-width: 220px;
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 100%;

    &:nth-child(1) {
      padding-top: 0;
    }
    &:nth-child(2) {
      padding-top: 30px;
    }
    &:nth-child(3) {
      padding-top: 30px;
    }
  }
`

const IllustrationWrapper = styled(ColorWrapper)`
  height: 195px;
  background-image: url(${IllustrationRippleBlue});
  background-color: ${grey};
  background-repeat: no-repeat;
  background-position: center center;

  @media (min-width: ${desktop}px) {
    height: 300px;
  }
`

const ourselvesItems = [
  {
    title: "Our Ambition",
    description:
      "At Social Supermarket, we believe that by partnering with impact-driven UK businesses, every purchase can be a force for good.",
  },
  {
    title: "Our Mission",
    description:
      "We make it easy to buy social enterprise products, meaning you can create positive impact with every purchase.",
  },
  {
    title: "Our Offering",
    description:
      "We partner with over 150 social enterprise brands to design memorable gifts that surprise and delight the recipient. Each one has a unique impact and story.",
  },
]

interface Props {}

const LPPrideBlock: FC<Props> = (): JSX.Element => {
  return (
    <div>
      <ColorWrapper background={grey}>
        <Block>
          <Part width="800px" padding="0 0 30px 65px" wrapPadding="0 0 25px 0">
            <LargeTitle>
              At Social Supermarket, we pride ourselves on selling meaningful products that
              <b> create ripples of positive change.</b>
            </LargeTitle>
          </Part>
          <OurselvesBlock>
            {ourselvesItems.map(({ title, description }) => (
              <OurselvesItem key={title}>
                <Title fontFamily={caflischFont}>{title}</Title>
                <Description margin="20px 0 0 0" tabletMargin="10px 0 0 0">
                  {description}
                </Description>
              </OurselvesItem>
            ))}
          </OurselvesBlock>
        </Block>
      </ColorWrapper>
      <IllustrationWrapper />
    </div>
  )
}

export default LPPrideBlock
