import React, { FC, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import BorderedButton from "../../components/button/BorderedButton"
import CorporateQuotes from "../../components/page/corporate/corporate-quotes/CorporateQuotes"
import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import { grey, skyBlue, white } from "../../constants/colors"
import { minionFont } from "../../constants/fonts"
import { bigDesktop, desktop, largeDesktop, smallMobile, tablet } from "../../constants/screenSizes"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import staticBackgroundStyles from "../../components/landing-page/staticBackgroundStyles"
import Block from "../../components/landing-page/Block"
import InnerBlock from "../../components/landing-page/InnerBlock"
import Part from "../../components/landing-page/Part"
import Title from "../../components/landing-page/Title"
import LargeTitle from "../../components/landing-page/LargeTitle"
import Subheading from "../../components/landing-page/Subheading"
import LPPrideBlock from "../../components/landing-page/new/components/selling-points/LPPrideBlock"
import { Grid, GridImage, GridItem } from "../../components/landing-page/Grid"
import PersonalizationBlock from "../../components/landing-page/PersonalizationBlock"
import Description from "../../components/landing-page/Description"
import IconTick from "../../../svg/icon-tick-white.inline.svg"
import ImageSustainability from "../../../images/image-sustainability.jpg"
import ImageTeamCuration from "../../../images/image-team-curation.jpg"
import MediaSocialImpact from "../../../images/media-social-impact.gif"
import BurstDesktop from "../../../svg/block-burst-white-desktop.svg"
import BurstTablet from "../../../svg/block-burst-white-tablet.svg"
import BurstMobile from "../../../svg/block-burst-white-mobile.svg"
import { Background, Parallax } from "react-parallax"
import LPHamperFaqAccordionBlock from "../../components/landing-page/new/components/faq/LPHamperFaqAccordionBlock"
import EnquiryFormModal from "../../components/landing-page/new/components/lead-capture/enquiry-form/EnquiryFormModal"

const MastheadPart = styled(Part)`
  @media (max-width: ${largeDesktop}px) {
    width: 525px;
  }
`

const ParallaxContainer = styled(Parallax)`
  height: 400px;

  .react-parallax-background-children {
    width: 100%;
  }
  .gatsby-image-wrapper {
    height: 400px !important;
  }
`

const MainTitle = styled.h1`
  margin: 20px 0;
  font-family: ${minionFont};
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 1.44px;
  color: ${white};

  @media (max-width: ${tablet}px) {
    margin: 12px 0 15px 0;
    font-size: 36px;
    line-height: 46px;
    letter-spacing: 1.08px;
  }
  @media (max-width: ${smallMobile}px) {
    margin: 20px 0;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.96px;
  }
`

const AdvantageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const AdvantageTick = styled(IconTick)`
  @media (max-width: ${tablet}px) {
    width: 12px;
  }
  @media (max-width: ${smallMobile}px) {
    width: unset;
    margin-top: 9px;
  }
`

const BrochureDescription = styled(Description)`
  color: ${white};

  @media (max-width: ${tablet}px) {
    margin-bottom: 40px;
  }
  @media (max-width: ${smallMobile}px) {
    margin-bottom: 30px;
  }
`

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 525px;
  height: 295px;

  @media (max-width: ${bigDesktop}px) {
    margin: 30px 0;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
  }
`

const WhoAreSocialDesktopTitle = styled.div`
  padding-bottom: 25px;

  @media (max-width: ${desktop}px) {
    display: none;
  }
`
const WhoAreSocialTabletTitle = styled.div`
  display: none;

  @media (max-width: ${desktop}px) {
    display: block;
  }
`

const borderWidth = 2

const BuildYourBoxBenefitContainer = styled.div`
  padding: 0 20px;

  @media (max-width: ${largeDesktop}px) {
    padding: 0;
  }
`

const Circle = styled.div<{ isFilled?: boolean }>`
  width: 10px;
  height: 10px;
  transform: translate(-1px);
  border-radius: 50%;
  ${({ isFilled }) =>
    isFilled ? `background: ${skyBlue}` : `border: ${borderWidth}px solid ${skyBlue}`};
  margin: 0 auto;

  @media (max-width: ${smallMobile}px) {
    transform: translate(-4px);
    margin: 0;
  }
`

const BuildYourBoxBenefit = styled.div<{ isRightAligned: number; index: number }>`
  width: 50%;
  display: flex;
  align-items: flex-start;
  padding: ${({ index }) => (index === 0 ? "60px 0 0 0" : 0)};
  ${({ isRightAligned }) =>
    isRightAligned
      ? `margin-left: calc(50% - ${borderWidth}px); border-left: ${borderWidth}px solid ${skyBlue}`
      : `flex-direction: row-reverse; border-right: ${borderWidth}px solid ${skyBlue}`};

  @media (max-width: ${smallMobile}px) {
    flex-direction: row;
    border-left: ${borderWidth}px solid ${skyBlue};
    border-right: none;
    width: 100%;
    margin-left: 0;
    padding-bottom: 40px;
  }
`

const Number = styled.div<{ isRightAligned: number }>`
  display: flex;
  align-items: center;
  ${({ isRightAligned }) => !isRightAligned && "flex-direction: row-reverse"};
  @media (max-width: ${smallMobile}px) {
    flex-direction: row;
  }
`

const Line = styled.div`
  width: 60px;
  height: ${borderWidth}px;
  background: ${skyBlue};
`

const NumberCircle = styled.div`
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: ${skyBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
`

const TextBlock = styled.div<{ isRightAligned: number }>`
  text-align: ${({ isRightAligned }) => (isRightAligned ? "start" : "end")};
  margin: ${({ isRightAligned }) => (isRightAligned ? "0 0 0 25px" : "0 25px 0 0")};
  @media (max-width: ${smallMobile}px) {
    text-align: start;
    margin: 0 0 0 25px;
  }
`

const InspirationPart = styled(Part)<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 460px;

  @media (max-width: ${bigDesktop}px) {
    width: 400px;
  }
  @media (max-width: ${desktop}px) {
    width: 330px;
  }
  @media (max-width: 735px) {
    width: 100%;
    max-width: unset;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`

const InspirationDescription = styled(Description)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.71px;
`

const EnquiryFormLink = styled.u<{ color?: string }>`
  color: ${({ color }) => color || `${skyBlue}`};
  text-decoration: underline;
  cursor: pointer;
`

const BurstWrapper = styled(ColorWrapper)`
  background-image: url(${BurstDesktop});
  background-color: ${skyBlue};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;

  @media (max-width: ${tablet}px) {
    background-image: url(${BurstTablet});
    background-size: cover;
  }
  @media (max-width: ${smallMobile}px) {
    background-image: url(${BurstMobile});
  }
`

const advantageItems = [
  "Gifts designed to your bespoke needs",
  "Deliver something impactful and sustainable",
  "Choose from over 1,400 social enterprise products",
]

const whyBuyItems = [
  {
    src: ImageSustainability,
    alt: "Sustainability",
    description:
      "Every item in our gifts is sustainable and ethical. That’s because we only work with brand partners who, like us, prioritise taking care of the planet.",
  },
  {
    src: ImageTeamCuration,
    alt: "Team curation",
    description:
      "Our team personally curates items to your brief, so whatever you’re looking for you can be sure it’s passed our standards of quality, great taste and positive impact.",
  },
  {
    src: MediaSocialImpact,
    alt: "Social impact",
    description:
      "See the social impact your gifts create. We make it easier to understand the positive ripple effect your items create when you shop with us.",
  },
]

const buildYourBoxBenefits = [
  {
    title: "Design",
    description:
      "Select your box and design from our range of different packaging options. We can offer branded gift boxes with your logo or a message on.",
  },
  {
    title: "Choose your Contents",
    description:
      "Decide which items you’d like in your tailor-made gift box from our range of social enterprise brands.",
  },
  {
    title: "Personalise",
    description:
      "Add a gift note or personalised message to each gift box to let them know it’s from you.",
  },
  {
    title: "Delivery",
    description:
      "We’ll work together to set a delivery date. We can send gifts to anywhere in the world – for bespoke gifts we can send from 150 to 25,000 boxes.",
  },
]

const personalizationTitle = (
  <>
    We can offer a range of <b>co-branding options</b> from gift boxes to tissue paper to booklets.
    <b>Add your company logo or a message.</b>
  </>
)

interface Props {}

const BespokeGiftsLandingPage: FC<Props> = (): JSX.Element => {
  const [videoUrl, setVideoUrl] = useState<string>(
    "https://www.youtube.com/embed/GhJTrU6j_54?autoplay=0"
  )
  const [showModal, setShowModal] = useState<boolean>()

  const videoContainerRef = useRef<HTMLDivElement>(null)

  const openEnquiryForm = () => {
    setShowModal(true)
  }

  const onVideoEnterViewport = () => {
    setVideoUrl("https://www.youtube.com/embed/GhJTrU6j_54?autoplay=1&mute=1")
  }

  const onVideoLeaveViewport = () => {
    setVideoUrl("https://www.youtube.com/embed/GhJTrU6j_54?autoplay=0")
  }

  useIntersectionObserver(
    videoContainerRef,
    "",
    1,
    onVideoEnterViewport,
    onVideoLeaveViewport,
    false
  )

  return (
    <Layout fullWidth>
      <SEO title="Bespoke Gifts" description="Bespoke Gifts" />
      <ColorWrapper>
        <StaticImage
          style={staticBackgroundStyles}
          src="../../../images/bespoke-gifiting-masthead.jpg"
          alt="Masthead background image"
        />

        <Block>
          <InnerBlock>
            <MastheadPart width="624px">
              <Subheading isBordered color={white}>
                Bespoke Gift Hampers
              </Subheading>
              <MainTitle>Let us tailor gifts just for you and your recipients</MainTitle>
              {advantageItems.map(item => (
                <AdvantageContainer>
                  <AdvantageTick />
                  <Description margin="0 0 0 25px" color={white}>
                    {item}
                  </Description>
                </AdvantageContainer>
              ))}
            </MastheadPart>
            <Part width="327px" padding="70px 0 0 0" wrapPadding="20px 0 0 0">
              <BorderedButton onClick={openEnquiryForm} icon="arrow right">
                Request a proposal
              </BorderedButton>
            </Part>
          </InnerBlock>
        </Block>
      </ColorWrapper>

      <Block>
        <InnerBlock padding="0 65px 0 0">
          <Part width="500px" padding="25px 0 0 0">
            <WhoAreSocialTabletTitle>
              <Subheading isBordered borderColor={skyBlue}>
                Who are social supermarket?
              </Subheading>
            </WhoAreSocialTabletTitle>
            <VideoWrapper ref={videoContainerRef}>
              <StyledIframe src={videoUrl} allow="autoplay; encrypted-media" frameBorder="0" />
            </VideoWrapper>
          </Part>
          <Part width="40%">
            <WhoAreSocialDesktopTitle>
              <Subheading isBordered borderColor={skyBlue}>
                Who are social supermarket?
              </Subheading>
            </WhoAreSocialDesktopTitle>
            <Title>
              We are the UK’s <b>leading social enterprise marketplace</b>
            </Title>
            <Description margin="10px 0 40px 0">
              Every brand that we work with has a social or environmental mission. That means they
              create net positive impact for people and planet. When you purchase from us, you’re
              also playing your part to help the world.
            </Description>
            <BorderedButton to="/about/" color={skyBlue} secondaryColor={white} icon="arrow right">
              Find out more
            </BorderedButton>
          </Part>
        </InnerBlock>
      </Block>

      <ColorWrapper background={grey}>
        <Block>
          <InnerBlock>
            <Part width="70%">
              <Subheading isBordered borderColor={skyBlue}>
                What are the bespoke hampers?
              </Subheading>
              <Title margin="25px 0 10px 0">
                Whatever you gift, <b>do it with impact</b>
              </Title>
              <Description>
                We curate the best items based on what you want for your team or clients. Fancy a
                box of purely vegan food and drink? We can do that. Are you looking for a new joiner
                pack with stationery and snacks alike? We’ve got you covered.
              </Description>
              <Description margin="45px 0 0 0" tabletMargin="30px 0 0 0">
                Simply give us a few details about what you’re looking for and we’ll be in touch
                with some options within your budget. Fill out an{" "}
                <EnquiryFormLink onClick={openEnquiryForm}>enquiry form</EnquiryFormLink> or call us
                on +44 7306 410 743 to get started today.
              </Description>
            </Part>
            <Part width="210px" padding="65px 0 0 0" wrapPadding="40px 0 0 0">
              <BorderedButton
                to="https://meetings.hubspot.com/barney-close?uuid=415ede49-cbc5-4e27-81e7-eef4f3ddd62c"
                color={skyBlue}
                secondaryColor={white}
                icon="arrow right"
              >
                Book a call
              </BorderedButton>
            </Part>
          </InnerBlock>
        </Block>
      </ColorWrapper>

      <ParallaxContainer>
        <Background>
          <StaticImage
            style={{
              width: "100%",
              height: "100%",
            }}
            src="../../../images/image-parallax.jpg"
            alt="Parallax image"
          />
        </Background>
      </ParallaxContainer>

      <Block>
        <Part padding="0 0 0 65px">
          <Subheading isBordered borderColor={skyBlue}>
            Why buy bespoke with us?
          </Subheading>
        </Part>
        <Grid>
          {whyBuyItems.map(({ src, alt, description }) => (
            <GridItem>
              <GridImage src={src} alt={alt} />
              <Description>{description}</Description>
            </GridItem>
          ))}
        </Grid>
      </Block>

      <Block>
        <Part width="650px" padding="0 0 80px 60px" wrapPadding="0 0 60px 0">
          <Title>
            Here's what <b>our customers</b> have to say
          </Title>
        </Part>
        <CorporateQuotes />
      </Block>

      <Block>
        <Part width="600px" padding="0 0 60px 65px" wrapPadding="0 0 50px 0">
          <LargeTitle>
            Build your box and become a <b>changemaker</b>
          </LargeTitle>
        </Part>
        <BuildYourBoxBenefitContainer>
          <Circle />
          {buildYourBoxBenefits.map(({ title, description }, index) => {
            const isRightAligned = index % 2
            return (
              <BuildYourBoxBenefit isRightAligned={isRightAligned} index={index}>
                <Number isRightAligned={isRightAligned}>
                  <Line />
                  <NumberCircle>0{index + 1}</NumberCircle>
                </Number>
                <TextBlock isRightAligned={isRightAligned}>
                  <Title>{title}</Title>
                  <Description>{description}</Description>
                </TextBlock>
              </BuildYourBoxBenefit>
            )
          })}
          <Circle isFilled />
        </BuildYourBoxBenefitContainer>
      </Block>

      <PersonalizationBlock title={personalizationTitle} />

      <ColorWrapper background={skyBlue}>
        <Block>
          <InnerBlock>
            <Part width="70%">
              <Subheading isBordered borderColor={white} color={white}>
                How to get started?
              </Subheading>
              <LargeTitle margin="25px 0 10px 0" color={white}>
                Want to discuss your bespoke gift hamper?
              </LargeTitle>
              <Description color={white}>
                To discuss ordering your bespoke gift boxes, either book a call with one of our team
                or get in touch via our{" "}
                <EnquiryFormLink onClick={openEnquiryForm} color={white}>
                  enquiry form
                </EnquiryFormLink>
                .
              </Description>
            </Part>
            <Part width="210px" padding="65px 0 0 0" wrapPadding="40px 0 0 0">
              <BorderedButton
                to="https://meetings.hubspot.com/barney-close?uuid=415ede49-cbc5-4e27-81e7-eef4f3ddd62c"
                icon="arrow right"
              >
                Book a call
              </BorderedButton>
            </Part>
          </InnerBlock>
        </Block>
      </ColorWrapper>

      <LPPrideBlock />
      <LPHamperFaqAccordionBlock openEnquiryForm={openEnquiryForm} />
      <BurstWrapper>
        <Block>
          <InnerBlock>
            <Part width="100%" padding="0 0 10px 0" wrapPadding="0 0 20px 0">
              <Subheading isBordered borderColor={white} color={white}>
                What next?
              </Subheading>
              <LargeTitle margin="30px 0 10px 0" color={white}>
                Looking for inspiration?
              </LargeTitle>
            </Part>
            <InspirationPart>
              <InspirationDescription color={white} margin="0 0 40px 0">
                Visit our marketplace to find gift boxes that can be shipped immediately or on a
                future date of your choosing.
              </InspirationDescription>
              <BorderedButton to="/product-category/hampers-and-gift-boxes/" icon="arrow right">
                Non-Bespoke Gifts
              </BorderedButton>
            </InspirationPart>
            <InspirationPart>
              <InspirationDescription color={white} margin="0 0 40px 0">
                Visit our Team Gifting Platform where you can view our ready-to-go gifts available
                to purchase in multiples.
              </InspirationDescription>
              <BorderedButton to="/team-gifting-platform/" icon="arrow right">
                Team gifting platform
              </BorderedButton>
            </InspirationPart>
          </InnerBlock>
        </Block>
      </BurstWrapper>
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry - Gifting"}
        name={"GiftingEnquiryForm"}
      />
    </Layout>
  )
}

export default BespokeGiftsLandingPage
